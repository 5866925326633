import { Avatar, Button, Col, Comment, Form, Input, List, Modal, Row, Skeleton, Tooltip } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { API } from '../../utility/utils';

moment.tz.setDefault('America/Mexico_City');

export default function CommentsLog({ entityId, handleClose, entity }) {
  const [open, setOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataFetched, setDataFetched] = useState();
  const [totalComments, setTotalComments] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (entityId) {
      setOpen(true);
    }
  }, [entityId]);

  useEffect(() => {
    if (open && entityId) {
      fetchData();
    }
  }, [open, entityId]);

  const formatData = items => {
    setTotalComments(items.length);
    return items.map(i => ({
      author: i.creator,
      avatar: <Avatar style={{ backgroundColor: '#0772df' }}>{i.creator[0].toUpperCase()}</Avatar>,
      comment: <p>{i.comment}</p>,
      datetime: (
        <Tooltip title={moment(i.timestamp).format('DD MMMM, YYYY, hh:mm A')}>
          <span>{moment(i.timestamp).fromNow()}</span>
        </Tooltip>
      ),
    }));
  };

  const fetchData = async () => {
    setDataFetched([]);
    setDataLoaded(false);
    const res = await API.get(`user/comments-logger/${entityId}`);
    const obj = res.data.data;
    setDataFetched(formatData(obj));
    setDataLoaded(true);
  };

  const handleSubmit = async ({ comment }) => {
    setIsLoading(true);
    await API.put(`user/comments-logger/${entityId}`, { comment, entity });
    await fetchData();
    setIsLoading(false);
  };

  return (
    <Modal
      type="primary"
      title="Bitácora de comentarios"
      visible={open}
      style={{ top: 20 }}
      destroyOnClose
      onCancel={() => {
        handleClose();
        setOpen(false);
        setDataFetched([]);
        setIsLoading(false);
      }}
      afterClose={() => {}}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            handleClose();
            setOpen(false);
            setDataFetched([]);
            setIsLoading(false);
          }}
        >
          Cerrar
        </Button>,
      ]}
    >
      {dataLoaded ? (
        <>
          <Form name="comments-form" onFinish={handleSubmit}>
            <Row>
              <Col span={24}>
                <Form.Item name="comment" rules={[{ required: true, message: 'Ingresa tus comentarios' }]}>
                  <Input.TextArea rows={4} placeholder="Agrega tus comentarios" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ float: 'right' }} loading={isLoading}>
                    Guardar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col span={24}>
              <List
                header={`${totalComments} comentarios`}
                itemLayout="horizontal"
                pagination={{
                  total: totalComments,
                  defaultCurrent: 1,
                  hideOnSinglePage: true,
                }}
                dataSource={dataFetched}
                renderItem={item => (
                  <li>
                    <Comment
                      author={item.author}
                      content={item.comment}
                      datetime={item.datetime}
                      avatar={item.avatar}
                    />
                  </li>
                )}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton paragraph={{ rows: 6 }} active />
      )}
    </Modal>
  );
}
